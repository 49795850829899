/*-- Website Css --*/
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
html, body {
    scroll-behavior: smooth;
    overflow-x: hidden;
} 
html::-webkit-scrollbar {
    width: 10px;
    background-color: #ebebeb;
}
html::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ed1b24;
}
body {
    font-family: 'Poppins', sans-serif;
}
body .container {
    max-width: 1320px;
}
img {
    width: 100%;
}
h4.small-hdn {
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ED1B24;
    margin: 0  0 15px;
}
h2.sec-hdng {
    font-size: 36px;
    font-weight: 600;
    margin: 0 0 20px;
    color: #000;
}
h2.hdng {
    font-size: 30px;
    color: #000;
    margin: 0 0 15px;
    font-weight: 600;
}
h5.hdng-h5 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 15px;
    color: #000;
}
p.hdng-16 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
p.hdng-18 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
.custom-btn:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
    transition: all 0.3s ease;
}
.custom-btn:hover {
    color: rgb(237 27 36);
}
.custom-btn:hover:after, .custom-btn-2:hover:after {
    top: auto;
    bottom: 0;
    height: 100%;
 }
.custom-btn {
    color: #fff;
    font-weight: 400;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: rgb(237 27 36);
    padding: 12px 30px;
    text-decoration: none;
    border: 1px solid rgb(237 27 36);
}
.custom-btn span, .custom-btn-2 span {
    position: relative;
    z-index: 9;
}
.custom-btn-2:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgb(237 27 36);
    transition: all 0.3s ease;
}
.custom-btn-2:hover {
    color: #fff;
}
.custom-btn-2 {
    color: rgb(237 27 36);
    font-weight: 400;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    background: #fff;
    padding: 12px 30px;
    text-decoration: none;
    border: 1px solid rgb(237 27 36);
}
.custom-btn-2:hover {
    color: #fff;
}
.blk:after {
    background-color: rgb(0, 0, 0);
}
.site-ul {
    padding: 0;
    margin: 0;
}
.site-ul li {
    list-style: none;
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    gap: 15px;
}
.site-ul li img {
    width: 22px;
    position: relative;
    top: 4px;
}
.logo-carousel {
    padding: 150px 0 90px;
    background: url(../images/top-shape.svg);
    background-position: top center;
    background-size: cover;
}
.logo-carousel li.react-multi-carousel-item {
    padding: 0 5px;
}
.logo-carousel .react-multi-carousel-item img {
    border: 1px solid #ed1b24;
}
.logo-carousel .react-multiple-carousel__arrow {
    background: #ed1b24;
    border-radius: 0;
    min-width: auto;
    min-height: auto;
    max-width: 30px;
    width: 100%;
    height: 30px;
}
.logo-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0;
}
.logo-carousel .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0;
}
.logo-carousel .react-multiple-carousel__arrow:before {
    font-size: 16px;
}
.accordion-item button.accordion-button {
    outline: 0;
    box-shadow: 0px 0px 0px 0px #fff;
}
.accordion-item .accordion-button.collapsed {
    padding: 20px;
    border-radius: 0;
    background: #fff;
    color: #000;
}
.accordion-item {
    margin-bottom: 15px;
    border-radius: 0 !important;
    border: 0px;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
}
.accordion-item .accordion-button {
    padding: 20px;
    background: #ed1b24;
    color: #ffff;
    font-size: 20px;
    border-radius: 0 !important;
    font-weight: 600;
}
.accordion-item .accordion-button.collapsed:after {
    filter: brightness(0) invert(0);
}
.accordion-item .accordion-button:after {
    filter: brightness(0) invert(1);
}
.accordion-body tr th {
    font-size: 18px;
    color: #000;
}
.accordion-body tr td {
    padding-bottom: 5px;
    font-size: 16px;
    color: #6a6a6a;
}
.accordion-body p {
    padding-bottom: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    color: #6a6a6a;
}
.accordion-body {
    padding: 0;
}
.accordion-body tr th {
    border: 1px solid #eaeaea;
    padding: 10px 20px;
}
.accordion-body tr td {
    border-bottom: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    padding: 10px 20px;
}
.accordion-body tr th:last-child {
    border-right: 0;
}
.accordion-body tr th:first-child, .accordion-body tr td:first-child {
    border-left: 0;
}
/*-- Website Css --*/



/*-- Header Css --*/
.site-header {
    background: #000;
    z-index: 99999;
    position: relative;
}
.site-header .navbar-collapse > ul.navbar-nav > li.nav-item > a.nav-link {
    min-height: 90px;
    display: flex;
    align-items: center;
}
.site-header .dropdown:hover > .dropdown-menu, .site-header .dropend:hover > .dropdown-menu {
  display: block;
  margin-top: 0.125em;
  margin-left: 0.125em;
}
.site-header .navbar-collapse {
    justify-content: flex-end;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a {
    padding: 0;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    position: relative;
}
.site-header .navbar-collapse .navbar-nav li.nav-item {
    padding: 0 15px;
}
.site-header .navbar a.navbar-brand {
    padding: 0;
    margin: 0;
}
.site-header .navbar a.navbar-brand img {
    width: 250px;
}
.site-header .navbar {
    padding: 0;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu {
    padding: 0;
    border: 0;
    border-radius: 0;
    margin: 0;
    box-shadow: 0px 0px 5px 0px #0000003b;
    left: 20px;
    min-width: 180px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a {
    color: #000;
    font-size: 15px;
    font-weight: 400;
    display: inline-block;
    width: auto;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li {
    padding: 10px 15px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item.dropend ul.dropdown-menu {
    left: 180px;
    top: 0;
}
.site-header .navbar-collapse .navbar-nav li.nav-item.dropend {
    padding: 0;
    position: relative;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a:hover:before {
    transform: scaleX(1);
    transform-origin: left center;
}
.site-header .navbar-collapse .navbar-nav li.nav-item a:before {
    content: '';
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    border-top-width: 0.143em;
    border-top-style: solid;
    transform: scaleX(0);
    transform-origin: right center;
    transition: transform 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    border-color: #ed1b24;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a:before {
    border-color: #000;
    border-top-width: 1px;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a:hover {
    background: transparent;
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li:hover {
    background: rgb(0 0 0 / 5%);
}
.site-header .navbar-collapse .navbar-nav li.nav-item .nav-link.dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.sticky {
    position: fixed;
    width: 100%;
    z-index: 99999;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
    background: rgb(0 0 0 / 80%);
}
.site-header .navbar-collapse .navbar-nav li.nav-item ul.dropdown-menu li a.dropdown-item {
    background: transparent !important;
}
/*-- Header Css --*/



/*-- Footer Css --*/
.site-footer {
    background: #333;
}
.site-footer .footer-content img {
    width: 100%;
}
.site-footer .footer-content p {
    font-size: 16px;
    margin-bottom: 0;
    color: rgb(255 255 255 / 70%);
    margin-top: 15px;
}
.site-footer .footer-content a.footer-logo {
    display: block;
    margin-bottom: 20px;
}
.site-footer .footer-links h4:after {
    content: '';
    background: #ed1b24;
    height: 1px;
    width: 50px;
    position: absolute;
    left: 0;
    bottom: -7px;
}
.site-footer .footer-links h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #fff;
    position: relative;
}
.site-footer ul {
    padding-left: 0;
}
.site-footer ul li {
    list-style: none;
    padding-bottom: 3px;
}
.site-footer .bottom-bar {
    border-top: 1px solid rgb(255 255 255 / 20%);
    text-align: center;
    padding: 15px 0;
}
.site-footer .bottom-bar p {
    margin: 0;
    font-size: 13px;
    color: rgb(255 255 255 / 70%);
}
.site-footer .bottom-bar p a {
    color: #ed1b24;
    font-weight: 600;
}
.top-footer {
    padding: 40px 0;
}
.site-footer ul li a {
    color: rgb(255 255 255 / 70%);
    text-decoration: none;
    font-size: 16px;
}
.site-footer ul li a:hover {
    color: #ed1b24;
}
.parallel-websites ul li a {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
}
.site-footer .col-md-3:nth-child(2) {
    padding-left: 100px;
}
.site-footer .col-md-3:nth-child(3) {
    padding-left: 50px;
}
.site-footer ul li b {
    color: #fff;
}
.parallel-websites li img {
    width: 30px;
}
.parallel-websites li {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 5px;
}
.social ul {
    display: flex;
    align-items: center;
    gap: 10px;
}
.social ul li img {
    width: 36px;
}
/*-- Footer Css --*/





/*-- HomePage Css --*/
.banner {
    background: url(../images/home-banner.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
    display: flex;
    align-items: center;
    padding: 100px 0 150px;
}
.banner-content h4 {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #FFFFFF;
    font-weight: 600;
    margin: 0;
}
.banner-content h1 {
    font-size: 56px;
    font-weight: 600;
    color: #FFFFFF;
    margin: 10px 0 15px;
}
.banner-content p {
    font-size: 20px;
    color: #FFFFFF;
    margin: 0;
}
.banner-content a.custom-btn {
    margin-top: 35px;
}
.banner-image {
    position: relative;
    text-align: center;
}
.banner-image img {
    width: 80%;
    z-index: 8;
    position: relative;
}
.banner-image img.abxo {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
}
.banner .row {
    align-items: center;
}
.about-block {
    padding: 80px 0;
}
.about-block-content .custom-btn {
    margin-top: 15px;
}
.about-block .row {
    align-items: center;
}
.home-points {
    padding: 60px 0;
    background: #111;
}
.home-points .row {
    align-items: center;
}
.home-points .points-content {
    padding-left: 40px;
}
.home-points .site-ul li {
    color: #fff;
}
.consulting-block {
    padding: 60px 0;
    background: #F8F9FC;
}
.consulting-hdngs {
    text-align: center;
    max-width: 750px;
    margin: 0 auto 25px;
}
.consulting-block .row {
    align-items: center;
}
.consulting-block .site-ul li span.image-circle {
    width: 100%;
    height: 55px;
    border: 1px solid #ed1b24;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 55px;
    padding: 12px;
    border-radius: 50%;
}
.consulting-block .site-ul li span.image-circle img {
    top: 0;
    width: 100%;
}
.consulting-block .site-ul li {
    align-items: center;
    margin-bottom: 20px;
    color: #6a6a6a;
}
.consulting-block .site-ul {
    margin-top: 30px;
}
.platform-block .row {
    align-items: center;
    padding: 40px 0;
    position: relative;
}
.platform-block {
    padding: 30px 0;
}
.platform-txt {
    max-width: 500px;
}
.platform-2 .platform-txt {
    margin: 0 0 0 auto;
}
.platform-txt a.custom-btn {
    margin-top: 15px;
}
.vector {
    position: absolute;
    width: 150px;
    bottom: -100px;
    left: 150px;
    right: 0;
    margin: 0 auto;
}
.vector.dg {
    left: -60px;
}
.diagno-block {
    padding: 100px 0;
    background: #111;
    position: relative;
    overflow: hidden;
}
.diagno-block-content h4, .diagno-block-content h2, .diagno-block-content p {
    color: #fff;
}
.diagno-block-content .custom-btn {
    margin-top: 20px;
}
.diagno-block .row {
    align-items: center;
}
.coaches-block {
    padding: 60px 0;
}
.coaches-box img {
    border-radius: 50%;
    max-width: 200px;
    margin: 0 auto 20px;
    display: block;
}
.coaches-box {
    text-align: center;
    padding: 0 40px;
}
.coaches-box h4.box-hdn {
    margin: 0;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    line-height: 26px;
}
.book-block {
    padding: 60px 0;
    background: #ed1b24;
}
.book-block h2.sec-hdng {
    color: #fff;
}
.curve-content {
    position: relative;
}
.curve-content:after {
    content: '';
    background: url(../images/bg-curve.svg) no-repeat;
    background-size: contain;
    width: 600px;
    height: 83px;
    top: -81px;
    position: absolute;
}
.curve-text {
    display: inline-block;
    max-width: 250px;
    text-align: center;
    position: relative;
    left: 170px;
    top: -40px;
}

/*-- HomePage Css --*/



/*-- AboutPage Css --*/

.about-banner {
    padding: 50px 0;
}
.about-sec-content h1 {
    font-size: 36px;
    margin: 0 0 20px;
    font-weight: 600;
}
.about-bar .row {
    position: relative;
}
.about-bar {
    padding: 20px 0 50px;
}
.about-bar .row:before {
    content: '';
    width: 98%;
    background: #000;
    height: 5px;
    top: -7px;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.about-bar .about-bar-txt {
    position: relative;
    text-align: center;
    padding-top: 20px;
}
.about-bar .about-bar-txt:before {
    content: '';
    background: #ed1b24;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.about-bar .about-bar-txt h4 {
    font-weight: 700;
}
.about-bar .about-bar-txt p {
    font-size: 14px;
}
.about-txt-block {
    padding: 30px 0;
}
.about-txt-block .row {
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.about-txt-block .row img.abxo {
    width: 75%;
}
.about-box-block {
    background: #F8F9FC;
    padding: 60px 0;
}
.about-box-block .about-box {
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 3%);
    background: #fff;
    padding: 15px 15px 20px;
    margin-bottom: 25px;
    min-height: auto;
}
.about-box-block .row {
    justify-content: center;
}
.about-box-block .about-box p {
    margin-top: 25px;
}
.about-box-block .about-box p {
    margin-top: 20px;
    margin-bottom: 0;
}
a.read-about {
    color: #ed1b24;
    font-weight: 600;
    text-decoration: none;
    text-transform: uppercase;
}
.about-box-block .about-box a.custom-btn {
    margin-top: 20px;
}
.about-box-block .col-md-4 .about-box {
    min-height: 560px;
}
.about-box-block .col-md-6 .about-box {
    min-height: 640px;
}
.about-box-block .row .col-md-6:last-child .about-box img {
    height: 415px;
    object-fit: contain;
}

/*-- AboutPage Css --*/



/*-- Benefits Css --*/

.buttons-benfi {
    display: flex;
    align-items: center;
    gap: 25px;
    margin-top: 25px;
}
.buttons-benfi .custom-btn {
    margin: 0;
}
.video-back iframe {
    display: inline-block;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 20px;
}
.benefits-block-content a.custom-btn-2 {
    margin-left: 20px;
}
.benefits-block-content a {
    margin-top: 20px;
}
.benefits-block {
    padding: 60px 0;
}
.benefits-block .row {
    align-items: center;
}
.modules-block .modules-block-hdng {
    text-align: center;
    max-width: 900px;
    margin: 0 auto 30px;
}
.modules-block .modules-block-hdng h2 {
    margin-bottom: 10px;
}
.platform-txt a.custom-btn-2 {
    margin-left: 20px;
}
.modules-block {
    padding: 60px 0;
    background: #f8f9fc;
}
.moduleblock_wht {
    background: #fff;
}
#nav-tab .nav-link {
    font-size: 14px;
    color: #000;
    background: #fff;
    border-radius: 0;
    border: 1px solid #e3e3e3dd;
    border-bottom: 0;
    width: 100%;
}
#nav-tab {
    gap: 5px;
    border: 0;
    margin: 0 !important;
    padding: 0;
    flex-wrap: unset;
}
#nav-tab .nav-link.active, #nav-tab .nav-link:hover {
    background: #ed1b24;
    color: #fff;
    border-color: #ed1b24;
}
.tab-content {
    background: #fff;
    border: 1px solid #e3e3e3dd;
    margin-top: 1px;
    padding: 30px;
}
.tools-page .tab-img img {
    border: 1px solid #e3e3e3dd;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 3%);
    padding: 19px;
}
.tab-cnt h4 {
    color: #000;
    font-weight: 600;
}
.tab-cnt ul {
    padding-left: 20px;
}
.tab-cnt ul li {
    color: #6a6a6a;
    margin-bottom: 5px;
}
ul.tools li {
    list-style: decimal;
}


/*-- Benefits Css --*/


/*-- Pricing Css --*/

.pricing-block {
    padding: 60px 0;
}
.benefits-block-content .custom-btn.gdl {
    margin-left: 20px;
}
.benefits-block-content .custom-btn-2, .benefits-block-content .custom-btn{
    text-align: center;
}
.price-page-box {
    display: block;
    width: 100%;
    border: 1px solid #818286;
    padding: 30px 25px;
    margin-bottom: 25px;
    position: relative;
    min-height: 410px;
}
.price-page-box ul {
    padding-left: 20px;
    margin: 10px 0 20px;
}
.price-page-box ul li {
    list-style: square;
    margin-bottom: 5px;
    font-weight: 600;
}
.price-page-box ul li strong {
    color: #ed1b24;
    font-weight: 600;
}
.price-page-box ul li:hover {
    color: #ed1b24;
}
.price-page-box p strong {
    font-size: 18px;
}
.price-page-box h3 {
    font-size: 30px;
    margin: 0;
    font-weight: 600;
}
.price-page-box p {
    margin-bottom: 5px;
}
.price-page-box:after {
    content: "";
    position: absolute;
    right: -21px;
    top: 0px;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #ed1b24;
}
.price-page-box.box-2:after {
    right: 0;
    top: auto;
    bottom: -21px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 20px solid #ed1b24;
    border-bottom: 0px solid transparent;
}
.price-page-box.box-4:after {
    right: auto;
    top: 0;
    bottom: auto;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-right: 20px solid #ed1b24;
    border-left: 0 solid transparent;
    left: -21px;
}
.price-page-box.box-3:after {
    display: none;
}
.price-page-box:hover {
    background: #333;
    color: #fff;
    transition: 0.5s;
}
.benefits-block-content iframe {
    margin-top: 20px;
}
.price-box h4 del {
    color: #ed1b24;
    font-weight: 400;
    font-size: 24px;
}
.pricing-ribbon {
    background: #ED1B24;
    color: #FFFFFF;
    top: 25px;
    right: -40px;
    position: absolute;
    padding: 5px 40px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    transform: rotate(45deg);
    z-index: 9;
}
.price-box {
    background: #F8F9FC;
    padding: 20px 20px 60px;
    border: 1px solid #e3e3e3dd;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 3%);
    position: relative;
    min-height: 800px;
    overflow: hidden;
}
.pricing-tabs .col:first-child .price-box {
    background: #333;
    color: #fff;
}
.price-box li {
    list-style: none;
    margin-bottom: 5px;
    font-size: 14px;
}
.price-box ul {
    padding-left: 20px;
    margin: 0;
    position: relative;
}
.price-box .custom-btn {
    display: block;
    text-align: center;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
}
.price-box p {
    text-transform: uppercase;
    color: #ed1b24;
    font-weight: 600;
}
.price-box h4 {
    margin: 10px 0;
    font-size: 30px;
    font-weight: 600;
    display: flex;
    align-items: center;
}
.price-box h4 sup, .price-box h4 span {
    font-size: 18px;
    margin-right: 5px;
    font-weight: 400;
}
.price-box h3 {
    font-size: 30px;
    font-weight: 600;
}
.price-box li:before {
    content: '';
    background: url(../icons/check-simple.svg) no-repeat;
    width: 12px;
    height: 8px;
    position: absolute;
    background-size: 100%;
    left: 0;
    margin-top: 7px;
}
.pricing-tabs .sec-hdng {
    text-align: center;
    margin-bottom: 35px;
}
.pricing-tabs {
    padding: 60px 0;
    border-top: 1px solid #00000024;
}
.pricing-text-block {
    padding: 60px 0;
    background: #f8f9fc;
}
.pricing-text-block h2.sec-hdng {
    text-align: center;
}
.price-text-box:hover {
    box-shadow: 5px 5px 0px 0px #ed1b24;
}
.price-text-box {
    background: #fff;
    border: 1px solid #e3e3e3dd;
    padding: 30px;
    text-align: left;
    margin-top: 25px;
    min-height: 320px;
    transition: 0.2s ease-in-out;
}
.price-text-box img {
    width: 70px;
    margin-bottom: 25px;
}
.price-text-box h4.box-hdn {
    font-size: 20px;
    color: #000;
    font-weight: 600;
}
/*-- Pricing Css --*/



/*-- Overview Css --*/
.overview-banner-block {
    background: #F8F9FC;
    padding: 60px 0;
}
.overview-banner-block .overview-block-content {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}
.overview-banner-block .overview-block-content a {
    margin-top: 15px;
}
.overview-page a.custom-btn-2 {
    margin-left: 20px;
}
.overview-banner-block .overview-block-content img {
    margin-top: 50px;
}
.logo-carousel h2.sec-hdng {
    text-align: center;
    margin-bottom: 40px;
}
.banner-image img.tab-gd {
    width: 85%;
}



/*-- Overview Css --*/







/*-- SMEBenefits Css --*/

.sme-box img {
    width: 70px;
    margin-bottom: 20px;
}
.sme-box p {
    margin-bottom: 0;
}
.sme-block {
    padding: 60px 0;
}
.sme-hdng {
    text-align: center;
    margin-bottom: 30px;
}
.sme-panel {
    max-width: 1000px;
    margin: 0 auto;
}
.sme-box {
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
    padding: 20px;
    text-align: center;
    border: 1px solid #e9e9e9;
}
.acc-block {
    background: #F8F9FC;
    padding: 60px 0;
}
.acc-block h2.sec-hdng {
    max-width: 1000px;
    margin: 0 auto 30px;
    text-align: center;
}

/*-- SMEBenefits Css --*/




/*-- Contact Css --*/

.address-maps {
    padding: 50px 0;
    background: #F8F9FC;
}
.team-slider .team-box > img {
    width: 200px;
    height: auto;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto 20px;
    display: block;
}
.team-slider .team-box {
    text-align: center;
}
.team-slider .team-box h3 {
    color: #000;
    font-weight: 600;
    font-size: 22px;
    margin: 0 0 10px;
}
.team-slider .team-box p {
    font-size: 16px;
    color: #6a6a6a;
    margin: 0;
}
.team-slider .team-box a {
    display: flex;
    align-items: center;
    color: #0077b7;
    font-weight: 600;
    text-decoration: none;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
}
.team-slider .team-box a img {
    width: 24px;
}
.team-slider button.react-multiple-carousel__arrow--left {
    left: 0;
}
.team-slider button.react-multiple-carousel__arrow--right {
    right: 0;
}
.team-slider {
    padding: 60px 0;
}
.team-slider h2.sec-hdng {
    text-align: center;
}
.team-slider .react-multi-carousel-list {
    margin-top: 40px;
}
.team-slider button.react-multiple-carousel__arrow {
    background: #ed1b24;
    border-radius: 0;
    min-width: auto !important;
    min-height: auto !important;
    width: 30px !important;
    height: 30px !important;
}
.team-slider button.react-multiple-carousel__arrow:before {
    font-size: 14px;
}
.form-cnt {
    padding: 50px 0;
}
.form-cnt .form-cnt-txt input, .form-cnt .form-cnt-txt textarea {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
    height: 60px;
    width: 100%;
    padding: 15px;
    color: #000;
    font-size: 16px;
    outline: 0;
}
.form-cnt .form-cnt-txt textarea {
    height: 120px;
}
.form-cnt .form-cnt-txt input::placeholder, .form-cnt .form-cnt-txt textarea::placeholder {
    color: #000;
    font-size: 16px;
}
.form-cnt .form-cnt-txt .form-feild {
    margin-bottom: 15px;
}
.form-cnt .form-cnt-txt .col-md-6, .form-cnt .form-cnt-txt .col-md-12 {
    padding: 0 7.5px;
}
.form-cnt .form-cnt-txt button.custom-btn {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
}
.form-cnt .form-cnt-txt .form-button {
    margin: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.form-cnt .form-cnt-txt .error-msg {
    position: relative;
    display: block;
    width: 100%;
    margin: 15px 0;
    font-size: 14px;
    background: #ed1b24;
    color: #fff;
    text-align: center;
    bottom: 0;
    padding: 5px 0px;
}
.form-cnt h2.sec-hdng, .form-cnt p.hdng-18 {
    text-align: center;
    margin-bottom: 10px;
}
.form-cnt .container > .row {
    margin-top: 50px;
}
.form-cnt .map {
    margin-top: 40px;
}
.form-cnt .container > .row > .col-md-6:first-child {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
}
.form-cnt .op-cnt {
    background: #fff;
    padding: 20px;
    box-shadow: 0px 12px 24px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
}
.form-cnt .op-cnt .contact-txt p {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
    color: #000;
    text-transform: uppercase;
}
.form-cnt .op-cnt .contact-txt a {
    font-size: 18px;
    color: #000;
    text-decoration: none;
}
.form-cnt .op-cnt .contact-txt-icon {
    background: #ed1b24;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    margin-bottom: 15px;
    padding: 15px;
}
.form-cnt .op-cnt .contact-txt a:hover {
    color: #ed1b24;
}

/*-- Contact Css --*/



/*-- Vertical Tabs Css --*/ 
.hamburger-mobile {
    display: none;
}
.tabs-help .tab-blocks .tab-content img {
    padding: 0;
    border: 0;
    box-shadow: 0 0 0 0 rgb(0 0 0);
}
.vertical-gd {
    position: fixed;
    left: 0;
    width: 300px;
    box-shadow: 0px 12px 23px 0px rgb(0 0 0 / 7%);
    background: #fff;
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 8rem;
}
.vertical-tabs .tab-content {
    width: calc(100% - 300px);
    margin: 0 0 0 auto;
    border: 0;
    padding: 50px;
}
.vertical-tabs {
    width: 100%;
}
.vertical-gd #nav-tab {
    gap: 0;
    border: 0;
}
.vertical-gd #nav-tab button.nav-link {
    background: transparent;
    border: 0;
    background: none;
    text-align: left;
    padding: 0px 20px;
}
.vertical-gd #nav-tab button.nav-link:hover, .vertical-gd #nav-tab button.nav-link.active {
    background: transparent;
    color: #ed1b24 !important;
}
.ultimate-user-guide-page + .site-footer {
    display: none;
}
.vertical-gd::-webkit-scrollbar {
    width: 5px;
    background-color: #ebebeb;
}
.vertical-gd::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ed1b24;
}
.vertical-gd #nav-tab button.nav-link.tab-hdng {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    margin-top: 15px;
    color: #000;
}
.vertical-gd #nav-tab button.nav-link.tab-sub-hdng {
    color: #6a6a6a;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    padding-left: 35px;
}
.tabs-help + .site-footer {
    display: none;
}
.tab-content .site-ul li {
    display: block;
    font-size: 16px;
    color: #6a6a6a;
}
.tab-content .site-ul li b {
    color: #000;
}
.tab-txts h2.hdng {
    color: #ed1b24;
    position: relative;
    display: inline-block;
}
.tab-txts h2.hdng:after {
    content: '';
    background: #ed1b24;
    height: 3px;
    width: 100%;
    position: absolute;
    bottom: -10px;
    left: 0;
    border-radius: 10px;
}
.tab-blocks {
    margin-top: 40px;
}
.tab-txts .tab-blocks  p.hdng-18 {
    margin-bottom: 5px;
    margin-top: 10px;
    font-weight: 600;
}
.tab-content ul.site-ul li:before {
    background: url(../icons/check-tick.png) no-repeat;
    content: '';
    width: 24px;
    height: 24px;
    position: relative;
    top: 3px;
    background-size: 100%;
    display: inline-block;
    margin-right: 5px;
}
.tab-txts .tab-blocks  ul.site-ul {
    margin-bottom: 20px;
}
.tab-txts p b {
    color: #000;
}   
.tab-blocks .row img {
    width: 100%;
}
.tab-blocks li img {
    width: auto;
}
.tab-blocks img {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 10px 23px 0 rgb(0 0 0/5%);
    padding: 25px;
    width: auto;
}
.vertical-gd #nav-tab h2 {
    background: #ed1b24;
    color: #fff;
    margin: 0;
    padding: 10px 20px;
    font-size: 24px;
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 15px;
}
.vertical-gd #nav-tab button.nav-link.tab-sub-hdng.key {
    padding-left: 20px;
}
.tab-content .site-ul li a {
    color: #ed1b24;
    font-weight: 600;
    font-size: 18px;
    text-decoration: none;
}
.tab-content .portal span {
    background: #ed1b24;
    color: #fff;
    padding: 4px 10px;
}
.portal .tab-blocks h5.hdng-h5 img {
    width: auto;
    padding: 5px 25px;
    margin-left: 30px;
}
.portal .tab-blocks {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(0,0,0,.1);
}
.video-padding {
    padding: 20px 0;
}
.portal .tab-blocks:last-child {
    border: 0;
    padding: 0;
    margin: 0;
}
.tab-content .portal .site-ul li img {
    display: block;
    margin-bottom: 30px;
    margin-top: 10px;
}
.tab-blocks #nav-tab .nav-link {
    font-size: 14px;
    color: #000;
    background: #fff;
    border-radius: 0;
    border: 1px solid #e3e3e3dd;
    border-bottom: 0;
    width: 100%;
}
.tab-blocks #nav-tab {
    gap: 5px;
    border: 0;
    margin: 0 !important;
    padding: 0;
    flex-wrap: unset;
}
.tab-blocks #nav-tab .nav-link.active, .tab-blocks #nav-tab .nav-link:hover {
    background: #ed1b24;
    color: #fff;
    border-color: #ed1b24;
}
.tab-blocks .tab-content {
    background: #fff;
    border: 1px solid #e3e3e3dd;
    margin-top: 1px;
    padding: 30px;
    width: 100%;
}
.tab-blocks .tab-cnt h4 {
    color: #000;
    font-weight: 600;
}
.tab-blocks .tab-cnt ul {
    padding-left: 20px;
}
.tab-blocks .tab-cnt ul li {
    color: #6a6a6a;
    margin-bottom: 5px;
}
.tab-blocks ul.tools li {
    list-style: decimal;
}
.tab-blocks .col3-tab .tab-cnt ul li {
    font-size: 14px;
}
.tab-blocks .col3-tab .tab-cnt h5 {
    color: #ed1b24;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}
.tab-blocks .middle-cnt ul li {
    list-style: square;
}
.tab-blocks .middle-cnt ul li::marker {
    color: #ed1b24;
}
.tab-blocks .col3-tab .tab-cnt p.hdng-16 {
    margin-bottom: 30px;
    font-size: 14px;
}
.tab-blocks h4.clr-hdng {
    color: #ed1b24;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}
.tab-blocks em {
    color: #ed1b24;
}
.ultimate-user-guide .site-header {
    position: fixed !important;
    width: 100%;
    background: #000 !important;
    z-index: 9999;
}
.ultimate-user-guide .site-header .container {
    max-width: 100%;
    padding: 0 20px;
}
.ultimate-user-guide .vertical-tabs {
    top: 90px;
    position: relative;
}

/*-- Vertical Tabs Css --*/ 





/*-- Exit Popup Css --*/ 
.gd-box {
    width: 370px;
    margin: 0 auto;
    background: #333;
    border: 5px solid #fff;
    position: relative;
    padding: 30px;
    color: #fff;
    border-radius: 10px;
}
.gd-box h2 {
    margin: 0;
    font-size: 30px;
    font-weight: 600;
}
.gd-box ul {
    padding-left: 20px;
    margin: 15px 0 20px;
}
.gd-box ul li {
    list-style: auto;
    padding-bottom: 5px;
    line-height: 22px;
    font-size: 15px;
}
.gd-box b {
    font-size: 20px;
    margin-bottom: 10px;
    display: block;
}
.gd-box p {
    font-size: 15px;
}
.exit-op {
    position: fixed;
    z-index: 99999999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
}
.gd-box .custom-btn {
    font-size: 14px;
    padding: 12px 40px;
}
.gd-box .close {
    position: absolute;
    left: auto;
    top: -20px;
    right: -20px;
    color: #fff;
    font-size: 18px;
    background-color: #5c5c5c;
    cursor: pointer;
    font-weight: bolder;
    text-decoration: none;
    margin-top: 0;
    border: 5px solid #fff;
    background-image: linear-gradient( -225deg, #231557 0%, #44107a 29%, #ff1361 67%, #fff800 100% );
    opacity: 1;
    z-index: 9999;
    width: 50px;
    border-radius: 50% 0% 50% 50%;
    height: 50px;
    pointer-events: all;
    background-size: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.exit-op.exit-box {
    display: flex;
    align-items: center;
}


/*-- Exit Popup Css --*/ 


/*-- Small Business Css --*/ 

.businessess-block {
    padding: 60px 0;
}
.businessess-block-growth h2.hdng {
    text-align: center;
    font-size: 24px;
}
.businessess-block-growth {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 25px;
}
.businessess-main {
    background: #ed1b24;
    padding: 20px;
    margin-top: 50px;
}
.businessess-main p.hdng-16 {
    color: #fff;
    font-style: italic;
}
.col3-tab .tab-cnt ul li {
    font-size: 14px;
}
.col3-tab .tab-cnt h5 {
    color: #ed1b24;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
}
.middle-cnt ul li {
    list-style: square;
}
.middle-cnt ul li::marker {
    color: #ed1b24;
}
.col3-tab .tab-img {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 25px;
}
.col3-tab .tab-cnt p.hdng-16 {
    margin-bottom: 30px;
    font-size: 14px;
}
h4.clr-hdng {
    color: #ed1b24;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 15px;
}



/*-- Small Business Css --*/ 





/*-- Download Css --*/ 

.download-txt {
    text-align: center;
}
.download-video {
    padding: 50px 0;
    text-align: center;
}
.download-video .video-block-download {
    margin-top: 20px;
}
.platform-block .banner-image img.abxo {
    width: 90%;
}
.download-video .video-block-download h5 {
    margin-top: 10px;
}

/*-- Download Css --*/ 



/*-- Tools Css --*/ 
.module-txt-gd {
    align-items: center;
}
.tools-page #nav-tab .nav-link {
    font-size: 18px;
}




/*-- Tools Css --*/ 



/*-- Blog  Css --*/ 

.blog-block {
    padding: 50px 0;
}
.blog-box {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 15px 15px 0;
    margin-bottom: 25px;
}
.blog-box img {
    height: 250px;
    object-fit: cover;
}
.blog-box h2 a {
    color: #000;
    text-decoration: none;
}
.blog-box h2 {
    font-size: 24px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.blog-box h2 a:hover {
    color: #ed1b24;
}
.blog-box p.date-txt {
    margin: 0 0 10px;
    font-size: 14px;
    color: #ed1b24;
    text-transform: uppercase;
    font-weight: 600;
}
.blog-box p.hdng-16 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}
.blog-box .blog-cnt {
    padding: 20px 0;
}
.blog-box a.read-btn {
    text-decoration: none;
    color: #ed1b24;
    font-weight: 600;
}

/*-- Blog  Css --*/ 


/*-- Contact Form Css --*/ 

.form-block {
    display: flex;
    justify-content: center;
    gap: 15px;
}
.form-block .form-feild {
    width: 100%;
}
.form-block .form-feild input, .form-block .form-feild select {
    width: 100%;
    height: 60px;
    border-radius: 0;
    border: 1px solid #dddd;
    padding: 0 15px;
    font-size: 16px;
    color: #000;
    outline: 0;
}
.form-block .form-feild input::placeholder {
    color: #000;
}
form.from-bottom .form-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.custom-btn.black:hover {
    border-color: #fff;
    color: #333;
}
.custom-btn.black {
    border: 0px;
    background: #333333;
    padding: 0;
    height: 55px;
    width: 150px;
    font-size: 18px;
}
p.error-msg {
    position: absolute;
    bottom: -45px;
    margin: 0;
    background: #fff;
    padding: 5px 50px;
    color: #000;
}
p.error-msg {
    position: absolute;
    bottom: -45px;
    margin: 0;
    padding: 5px 50px;
    color: #000;
    background: #4CAF50;
}
/*-- Contact Form Css --*/ 






/*-- Breadcrumb Css --*/ 
.breadcrumb {
    background: #ed1b24;
    padding: 10px 0;
}
.breadcrumb a, .breadcrumb {
    color: #fff;
    text-transform: capitalize;
}
.breadcrumb a {
    font-weight: 700;
    text-decoration: none;
}

/*-- Breadcrumb Css --*/ 


/*-- Blog Detail Css --*/ 
.blog-detail-cnt h1 {
    font-size: 36px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
}
.blog-txt {
    padding-top: 20px;
    font-size: 16px;
    color: #6a6a6a;
}
.blog-detail-cnt img.post-img {
    height: 450px;
    object-fit: cover;
}
.blog-txt .sign-blog {
    background: #e9e9e9;
    border-left: 5px solid rgb(237 27 36);
    margin: 25px 0;
    padding: 15px 20px;
}
.blog-txt .sign-blog p {
    font-size: 18px;
    font-style: italic;
    margin: 0;
    color: #000;
}
.blog-txt .sign-blog p a {
    font-weight: 600;
    font-style: normal;
    text-decoration: none;
}
.blog-txt h4 {
    margin: 30px 0 10px;
    font-size: 24px;
    font-weight: 600;
    color: #000;
}
.blog-share {
    display: flex;
    gap: 15px;
    align-items: center;
    padding: 30px 0;
    border-top: 1px solid rgb(0 0 0 / 10%);
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    margin: 40px 0;
}
.blog-share h5.hdng-h5 {
    margin: 0;
    margin-right: 30px;
}
.blog-share button {
    border: 0;
    outline: 0;
    padding: 0;
    background: transparent;
}
.blog-txt a {
    font-weight: 600;
    color: #ed1b24;
    text-decoration: none;
}
.blog-share button img {
    width: 30px;
}
.blog-detail-cnt {
    padding: 50px 0;
}
.sidebar {
    margin-top: 50px;
}
.sidebar .blog-sidebar ul {
    padding: 0;
    margin: 0;
}
.sidebar .blog-sidebar ul li {
    list-style: none;
    padding-bottom: 15px;
}
.sidebar .blog-sidebar ul li a {
    color: #000;
    text-decoration: none;
}
.sidebar .blog-sidebar ul li span {
    display: block;
    font-size: 14px;
    font-weight: 700;
    color: #ed1b24;
}
.sidebar .blog-sidebar ul li a:hover {
    color: #ed1b24;
}
.sidebar .blog-sidebar {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 20px;
    margin-bottom: 25px;
}
.form-block.post-comment .form-feild textarea {
    width: 100%;
    height: 120px;
    border-radius: 0;
    border: 1px solid #dddd;
    padding: 15px;
    font-size: 16px;
    color: #000;
    outline: 0;
    margin-top: 25px;
}
form.from-bottom .post-comment  .form-button {
    justify-content: flex-start;
}
.blog-comment .from-bottom {
    margin-bottom: 0;
    margin-top: 30px;
}
.form-block.post-comment {
    position: relative;
}
.form-block.post-comment p.error-msg {
    bottom: -40px;
    background: #ed1b24;
    color: #fff;
}
.form-block.post-comment .form-feild textarea::placeholder {
    color: #000;
}
/*-- Blog Detail Css --*/ 



/*-- Popup Css --*/ 

.ReactModal__Overlay {
    background: rgb(0 0 0 / 70%) !important;
    z-index: 99999;
}
.ReactModal__Overlay .ReactModal__Content {
    width: 800px;
    border-radius: 0 !important;
    margin: 0 auto;
    height: auto;
    inset: unset !important;
    left: 0 !important;
    right: 0 !important;
    top: 150px !important;
    overflow: visible !important;
}
.ReactModal__Overlay .ReactModal__Content button.close {
    font-size: 0;
    border: 0;
    position: absolute;
    top: -20px;
    right: -20px;
    width: 40px;
    outline: 0;
    background: #ed1b24;
    height: 40px;
    border-radius: 50%;
}
.ReactModal__Overlay .ReactModal__Content button.close:after {
    content: '✕';
    color: #fff !important;
    font-size: 22px;
}
.popup-gd {
    cursor: pointer;
}

/*-- Popup Css --*/ 











/*-- Lifetime Popup Css --*/ 

.ReactModal__Overlay .ReactModal__Content.lifetime-form {
    background: #fff;
    width: 400px;
    position: fixed;
    padding: 30px;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form input, .ReactModal__Overlay .ReactModal__Content.lifetime-form textarea {
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 0;
    padding: 15px;
    font-size: 14px;
    color: #000;
    outline: 0;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form textarea {
    height: 150px;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form .form-feild {
    margin-bottom: 15px;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form input:focus, .ReactModal__Overlay .ReactModal__Content.lifetime-form textarea:focus {
    border-color: #ed1b24;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form .form-button {
    margin: 0;
    justify-content: flex-start;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form p.error-msg {
    position: absolute;
    left: 0;
    font-size: 13px;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    background: #ed1b24;
    color: #fff;
}
.ReactModal__Overlay .ReactModal__Content.lifetime-form p.success-msg {
    position: absolute;
    left: 0;
    bottom: -45px;
    font-size: 13px;
    padding: 5px 0;
    width: 100%;
    text-align: center;
    background: #4CAF50;
    color: #fff;
    margin: 0;
}

/*-- Lifetime Popup Css --*/ 







/*-- Accountant Css --*/ 

.acc-hndg {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
}
.accontant-block {
    padding: 60px 0;
    background: #F8F9FC;
}
.acc-graph {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-column-gap: 100px;
    margin-top: 40px;
}
.acc-box {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 10px 23px 0 rgb(0 0 0/5%);
    padding: 45px;
    text-align: center;
    position: relative;
    background: #fff;
}
.acc-box h4 {
    margin: 0;
}
.acc-graph img {
    position: absolute;
    width: 90px;
    right: -90px;
    top: 0;
    bottom: 0;
    margin: auto;
}
.accontant-data {
    padding: 60px 0;
}
.accont-blocks {
    margin-bottom: 30px;
}
.accont-blocks h2 {
    font-size: 24px;
    margin: 0 0 10px;
}
.gd-hdng {
    background: #ed1b24;
    padding: 20px 0;
}
.gd-hdng h2.sec-hdng {
    margin: 0;
    text-align: center;
    color: #ffffff;
    font-size: 30px;
}
.potential-data {
    padding: 50px 0;
}
.potential-data.potential-bg {
    background: #F8F9FC;
}
.potential-data a.custom-btn {
    margin-top: 10px;
}
.potential-data .site-ul li {
    font-size: 16px;
    margin: 0 0 5px;
    color: #6a6a6a;
}
.potential-block h5 {
    color: #ed1b24;
    text-transform: uppercase;
    font-weight: 600;
}
.accontant-block video.myVideo {
    width: 100%;
}
.accontant-block video.myVideo {
    width: 92%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    right: 00;
    border-radius: 0;
    min-height: 550px !important;
}
.video-potential {
    position: relative;
    max-width: 75%;
    margin: 0 auto 40px;
}
.video-gd {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 30px auto 0;
    width: 92.5%;
}
.accountant-box.bg-gd {
    background: #f8f9fc;
}
.accountant-box {
    padding: 50px 0;
}
.accountant-box .site-ul li {
    font-size: 16px;
    margin: 0 0 5px;
    color: #6a6a6a;
}
.accountant-box .site-ul {
    margin-bottom: 10px;
}
.accountant-box  a.custom-btn {
    margin-top: 10px;
}
.box-gd h5 {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}
.box-gd h4 {
    text-transform: uppercase;
    color: #ed1b24;
    font-weight: 700;
    font-size: 36px;
}
.accountant-box .row {
    align-items: center;
}
.account-box-gd {
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 10px 23px 0 rgb(0 0 0/5%);
    background: #fff;
    padding: 30px;
    margin: 15px 0;
}


/*-- Accountant Css --*/ 



/*-- 404 Css --*/ 

.notfoundblock {
    text-align: center;
}
.notfoundblock img {
    width: 1000px;
    margin: 0 auto;
}

/*-- 404 Css --*/ 



/*-- Service Css --*/ 


.assesment {
    padding: 40px 0;
    background: #ed1b24;
    position: relative;
}
.assement-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.banner .banner-hdng h1 {
    font-size: 120px;
    font-weight: 900;
    line-height: 120px;
}
.assesment .rocket {
    position: absolute;
    width: 300px;
    left: 0;
    right: -300px;
    margin: auto;
}
.assement-block .assesment-hdng, .assement-block .assesment-btn {
    width: 100%;
}
.assement-block .assesment-btn {
    text-align: right;
}
.assement-block .assesment-hdng .hdng-h3 {
    color: #fff;
}
.assesment-hdng .hdng-h3 {
    margin: 0;
}
.technologies {
    padding: 80px 0;
    background: #f8f9fc;
}
.technologies .technologies-block {
    max-width: 900px;
    margin: 0 auto;
    text-align: center;
}
.satisfaction .counter h3 {
    font-size: 44px;
    color: #ed1b24;
    font-weight: 600;
}
.satisfaction .counter p {
    margin: 0;
    font-size: 18px;
}
.satisfaction .col-md-9 {
    display: flex;
    align-items: center;
}
.satisfaction .col-md-9 .counter {
    width: 100%;
}
.customers-outlook {
    padding: 80px 0;
}
.customers-hdng {
    text-align: center;
}
.customers-hdng img {
    width: auto;
    margin-top: 30px;
}
.tech-block {
    margin-top: 30px;
}
.reasons h2 {
    text-align: center;
}
.tech-block .tech-box:hover {
    background: #111;
}
.tech-block .tech-box:hover img {
    filter: invert(1);
}
.tech-block .tech-box:hover h6 {
    color: #fff;
}
.tech-block .tech-box {
    border-radius: 5px;
    padding: 20px 0;
    display: inline-block;
    width: 110px;
    margin: 5px;
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    background: #fff;
}
.tech-block .tech-box img {
    height: 35px;
    margin-bottom: 10px;
}
.tech-block .tech-box h6 {
    color: #666;
    font-size: 14px;
    margin: 0;
}
.reasons {
    background: #fdf7f7;
    padding: 80px 0;
}
.reasons h3.hdng-h3 {
    text-align: center;
}
img.down, img.up {
    position: absolute;
    width: auto;
    top: 50px;
    margin: auto;
    left: -50px;
}
.reasons-block img {
    background: #fff;
    border-radius: 50%;
    padding: 30px;
    width: 150px;
    height: 150px;
    margin: 0 auto 30px;
    display: block;
    border: 3px solid #111;
}
.reasons-block {
    text-align: center;
}
.reasons .col-md-4 {
    position: relative;
}
img.up {
    right: -50px;
    left: auto;
}
.reasons-btn {
    text-align: center;
    margin-top: 50px;
}
.reasons .row {
    margin-top: 50px;
}
.satisfaction {
    padding-top: 50px;
    background: #fff;
}
.work-slide h6 {
    font-size: 20px;
    text-transform: uppercase;
    color: #ed1b24;
}
.blocks-txt h4 img {
    width: 20px;
    margin-right: 20px;
}
.work-slide p {
    padding: 0 20px;
}
.work-slide:hover .work-img:after, .work-slide:hover .work-img a {
    opacity: 1;
}
.work-slide img {
    margin: 0 auto;
    border-radius: 10px;
}
.work-img {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.work-img:after {
    content: '';
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease .3s;
    height: 100%;
    width: 100%;
    position: absolute;
}
.work-img a.custom-btn {
    z-index: 99;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 180px;
    height: 50px;
    top: 0;
    opacity: 0;
    margin: auto;
    font-size: 18px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.work .slick-slider {
    margin-top: 30px;
}
.home.work {    
    border-top: 1px solid #ddd;
}
.work {
    padding: 80px 0;
}
.work-slide {
    padding: 0 20px;
    margin: 40px 0;
}
.work-hdng {
    text-align: center;
}
.work-slide-content {
    margin: 25px auto 0;
}
.work .slick-prev:before, .work .slick-next:before {
    color: #111;
    background: url(/src/Components/ServicesElements/images/left-arrow.svg) no-repeat;
    background-size: 100%;
    font-size: 0;
    width: 40px;
    height: 40px;
    display: block;
    position: absolute;
    opacity: 1;
}
.work .slick-next:before {
    background: url(/src/Components/ServicesElements/images/right-arrow.svg) no-repeat;
    background-size: 100%;
}
.work .slick-next {
    right: -35px; 
}
.work .slick-prev {
    left: -50px; 
}
.assement-block .assesment-hdng h2 {
    font-size: 30px;
    color: #fff;
    margin: 0;
}
section.service-banner {
    padding: 120px 0 50px;
    background: url(../images/home-banner.jpg);
    background-size: cover;
    background-position: center center;
    position: relative;
}
section.service-banner .banner-content h4 {
    color: #ed1b24;
}
.service-banner .banner-content h1 {
    font-size: 44px;
    margin: 20px 0;
}
.service-banner-box p {
    margin: 0;
    color: #6a6a6a;
    font-size: 14px;
}
.service-banner-box {
    border: 1px solid #ddd;
    background: #f8f9fc;
    border-left: 5px solid rgb(237 27 36);
    margin-top: 40px;
    padding: 25px;
}
.banner-form {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    margin-bottom: 25px;
    background: #f8f9fc;
    padding: 30px;
    margin-left: 50px;
}
.banner-form p {
    text-align: center;
}
.banner-form h3 {
    font-size: 24px;
    text-align: center;
}
.service-banner-box h5 {
    font-size: 18px;
}
.banner-form .form-feild input, .banner-form .form-feild textarea, .banner-form .form-feild select {
    border-radius: 0;
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
    height: 60px;
    width: 100%;
    padding: 15px;
    color: #000;
    font-size: 16px;
    outline: 0;
}
.banner-form .form-feild textarea {
    height: 120px;
}
.banner-form .form-feild input::placeholder, .banner-form .form-feild textarea::placeholder {
    color: #000;
    font-size: 16px;
}
.banner-form .form-feild {
    margin-top: 20px;
}
.banner-form .from-bottom .form-button {
    justify-content: flex-start;
}
.banner-form .from-bottom .form-button .custom-btn {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
}
.service-banner .row {
    align-items: baseline;
}
.service-banner-box img {
    width: 30px;
    margin-bottom: 15px;
}
.marque-slides {
    background: #000;
    padding: 30px 0;
}
.marque-slides .marquee p a img {
    width: 30px;
    margin-right: 20px;
}
.service-marque .marquee p img {
    width: 35px;
}
.service-marque {
    border-top: 3px solid #000;
    border-bottom: 3px solid #000;
    padding: 40px 0;
}
.service-marque  .marquee {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    --offset: 50vw;
    --item-font-size: 10vw;
    animation: marquee-2 10s linear infinite;
}
.service-gd .technologies-block {
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
}
.service-gd {
    padding: 80px 0;
    background: #f8f9fc;
}
.service-marque .marquee p {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 20px;
    font-weight: 600;
    padding: 0 25px;
}
.marque-slides .marquee p a {
    color: #fff;
    text-decoration: none;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: 800;
}
.marquee {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    position: relative;
    --offset: -30vw;
    --item-font-size: 10vw;
    animation: marquee 15s linear infinite;
}
.marquee__inner {
    width: fit-content;
    display: flex;
    position: relative;
}
.marquee p {
    white-space: nowrap;
    padding: 0 50px;
    margin: 0;
}
.service-gd .technologies-block {
    text-align: center;
}
.service-gd {
    padding: 80px 0;
}
.service-block-gd {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    border-radius: 10px;
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid rgb(0 0 0 / 10%);
    margin-top: 40px;
    overflow: hidden;
    background: #fff;
}
.service-gd-box img {
    width: 70px;
    margin-bottom: 20px;
}
.service-gd-box {
    text-align: center;
}
.service-gd-box:last-child {
    background: #000;
}
.service-gd-box:last-child h4, .service-gd-box:last-child p {
    color: #fff;
}
.service-gd-box:last-child h4 {
    font-size: 36px;
}
.service-gd-box h4 {
    font-weight: 600;
    margin-bottom: 15px;
}
.service-gd-box p {
    margin: 0;
}
.service-gd-box {
    padding: 50px 28px;
    border-left: 1px solid rgb(0 0 0 / 10%);
}
.service-gd-box:nth-child(1), .service-gd-box:nth-child(4) {
    border-left: 0;
}
.service-gd-box a {
    margin-top: 20px;
}
.service-gd-box:nth-child(1), .service-gd-box:nth-child(2), .service-gd-box:nth-child(3) {
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}
.hire {
    padding: 80px 0 0;
    background: #f8f9fc;
}
.hire-txt .site-ul {
    margin-bottom: 25px;
}
.hire-txt .site-ul li {
    list-style: circle;
    font-size: 16px;
    color: #6a6a6a;
    margin-bottom: 5px;
    display: block;
}
.hire-txt .site-ul li img {
    top: 0;
    margin-right: 10px;
}
.hire-txt {
    padding-right: 35px;
}
.hire-img {
    padding-left: 60px;
}
.service-page .logo-carousel {
    background: #f8f9fc;
    padding: 60px 0;
}
.service-tab {
    padding: 80px 0;
}
.service-tab .nav {
    display: inline-block;
}
.service-tab .nav-tab {
    display: flex;
}
.service-tab .nav-tab .tab-content {
    width: 100%;
    margin-left: 20px;
    padding: 0;
    padding-left: 100px;
    border: 0;
}
.service-tab .nav button {
    padding: 15px;
    min-width: 270px;
    text-align: left;
}
.service-tab .nav button.nav-link:last-child {
    border-bottom: 1px solid #e3e3e3dd !important;
}
.tabservice-overview a.custom-btn {
    margin-top: 20px;
}
.service-tab .nav-tab .tab-content h3 {
    font-weight: 600;
    margin-bottom: 10px;
    font-size: 24px;
}
.service-tab .nav-tab .tab-content .tabservice-list .tab-service {
    margin-top: 20px;
}
.service-tab .nav-tab .tab-content .tabservice-list .tab-service h5 {
    color: #6a6a6a;
    font-weight: 400;
    font-size: 16px;
}
.service-tab .nav-tab .tab-content .tabservice-list .tab-service h4 {
    font-size: 20px;
    margin-bottom: 5px;
    font-weight: 600;
}
.service-tab .nav-tab .tab-content .tabservice-list .tab-service img {
    width: 30px;
    float: left;
    margin-right: 20px;
}
.service-tab .nav-tab .tab-content .tabservice-list .tab-service p {
    font-size: 12px;
    color: #6a6a6a;
    margin: 0;
}
.service-tab h2.sec-hdng {
    text-align: center;
    margin-bottom: 40px;
}
.budget-radio {
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    margin-top: 10px;
}  
.budget-radio label {
    display: flex;
    align-items: center;
}  
.budget-radio input[type='radio'] {
    margin-right: 0;
    display: none;
}
.budget-radio label span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    transition: all .5s ease;
    -webkit-transition: all .5s ease;
    z-index: 0;
}
.budget-radio label span {
    position: relative;
    padding: 10px 0;
    font-size: 14px;
    width: 100%;
    text-align: center;
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
}
.budget-radio input:checked + span {
    background-color: #ed1b24;
    border-color: #ed1b24;
    color: #fff;
    font-weight: 500;
}
.banner-form .form-feild > label {
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #ed1b24;
    font-weight: 600;
}   
.servcies #chipbot-app {
    display: none;
}
.servcies .ReactModal__Overlay .ReactModal__Content.lifetime-form {
    width: 600px;
    position: fixed;
    padding: 40px 30px 30px;
}
.servcies .ReactModal__Overlay .ReactModal__Content.lifetime-form select {
    width: 100%;
    height: 50px;
    border: 1px solid #ddd;
    border-radius: 0;
    padding: 13px;
    font-size: 14px;
    color: #6a6a6a;
    outline: 0;
}
.servcies .ReactModal__Overlay .ReactModal__Content.lifetime-form .budget-radio label span {
    box-shadow: 0 0 0 0 rgb(0 0 0 / 2%);
    color: #6a6a6a;
}
.servcies .ReactModal__Overlay .ReactModal__Content.lifetime-form .budget-radio input:checked + span {
    color: #fff;
}
.servcies .ReactModal__Overlay .ReactModal__Content.lifetime-form select:focus {
    border-color: #ed1b24;
}
.servcies .platform-txt .site-ul li {
    list-style: circle;
    font-size: 16px;
    color: #6a6a6a;
    margin-bottom: 5px;
    display: block;
}
.servcies .platform-txt .site-ul li img {
    top: 0;
    margin-right: 10px;
}
.service-gd-box:last-child .popup-gd {
    margin-top: 25px;
}
.tabservice-overview .popup-gd {
    margin-top: 25px;
}
.work-slide-content h4.hdng-h4 a {
    color: #000;
    text-decoration: none;
}
.banner-form p.error-msg {
    width: 100%;
    bottom: 0;
    position: relative;
    margin-top: 25px;
    background: #ed1b24;
    color: #fff;
}
.banner-form .success-msg {
    width: 100%;
    bottom: 0;
    position: relative;
    margin-top: 25px;
    background: #4CAF50;
    color: #fff;
    padding: 5px 0;
}
.banner-form .form-button {
    flex-wrap: wrap;
}
.servcies .ReactModal__Overlay {
    background: rgb(0 0 0 / 100%)!important;
}
.servcies .work {
    padding: 80px 0 20px;
}
.platform-txt .popup-gd {
    margin-top: 25px;
}


.service-price-box:hover {
    border: 2px solid rgb(237 27 36);
}
.service-price-box {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 2px solid rgb(0 0 0 / 10%);
    padding: 1.5rem;
    position: relative;
    border-radius: 0.5rem;
    margin-bottom: 50px;
    transition: 0.3s;
}
.service-price-box .site-ul {
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-bottom: 20px;
    margin-top: 10px;
}
.service-price-box .site-ul::-webkit-scrollbar {
    width: 5px;
    background-color: #ebebeb;
}
.service-price-box .site-ul::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ed1b24;
}
.service-price-box .site-ul li {
    font-size: 14px;
    gap: 8px;
    align-items: center;
    margin-bottom: 5px;
}
.service-price-box .site-ul li img {
    width: 18px;
    top: 0;
}
.service-price-box p.offer {
    text-align: center;
    text-transform: uppercase;
    color: #ed1b24;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
}
.service-price-box h3 {
    font-size: 24px;
    font-weight: 600;
    max-width: 250px;
}
.service-price-box p {
    color: #6a6a6a;
}
.service-price-range {
    display: flex;
    gap: 15px;
    align-items: center;
}
.service-price-range h4 {
    font-size: 36px;
    font-weight: 700;
}
.service-price-range span {
    font-size: 18px;
    color: #ed1b24;
    font-weight: 600;
}
.service-price-box .popup-gd {
    position: absolute;
    text-align: center;
    left: 0;
    right: 0;
}
.service-price-box .popup-gd .custom-btn {
    font-size: 14px;
    padding: 10px 40px;
    border-radius: 5px;
    overflow: hidden;
}
.service-pricing {
    padding: 80px 0;
}
.service-price-box .pricing-ribbon {
    right: -35px;
    border-radius: 50px 50px 0 0;
}
.banner-gd-for {
    position: absolute;
    z-index: 99;
    background: #00000069;
    padding: 10px 0;
}
.banner-gd-for .marquee p a {
    font-size: 20px;
    font-weight: 600;
    pointer-events: none;
}
.banner-gd-for .marquee p {
    padding: 0 30px;
}
.banner-gd-for .marquee p a img {
    margin-right: 15px;
}
.banner-gd-for .marquee {
    animation: marquee 20s linear infinite;
    --offset: 100vw;
}
.service-pricing .technologies-block {
    text-align: center;
    margin: 0 auto 40px;
    max-width: 900px;
}
.servcies .from-bottom h3 {
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 24px;
    color: #ed1b24;
    font-weight: 600;
}

/* .service-price-box {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 5%);
    border: 1px solid #F1CEBE;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
}
.service-price-box h3 {
    text-align: center;
    background: #F1CEBE;
    margin-bottom: 0;
    padding: 15px 0;
    font-size: 24px;
    font-weight: 600;
}
.price-box2 {
    border: 1px solid #C3EAD8;
}
.price-box2 h3 {
    background: #C3EAD8;
}
.price-box3 {
    border: 1px solid #C8D4F2;
}
.price-box3 h3 {
    background: #C8D4F2;
}
.price-box4 {
    border: 1px solid #EEF2C8;
}
.price-box4 h3 {
    background: #EEF2C8;
}
.service-price-box .price-box {
    padding: 20px;
    min-height: auto;
    background: #fff;
    border: 0;
    box-shadow: 0px 0 0 0px #fff;
}
.service-price-box .price-box .custom-btn {
    width: auto;
    position: relative;
    margin-top: 25px;
    color: #000;
    border-radius: 5px;
    overflow: hidden;
    background: #F1CEBE;
    border-color: #F1CEBE;
}
.price-box2 .price-box .custom-btn {
    background: #C3EAD8;
    border-color: #C3EAD8;
}
.price-box3 .price-box .custom-btn {
    background: #C8D4F2;
    border-color: #C8D4F2;
}
.price-box4 .price-box .custom-btn {
    background: #EEF2C8;
    border-color: #EEF2C8;
} */



/* ----- FNB MasterUp ----- */

/* .banner-fnb {
    background: url(/src/Components/FNBMasterUP/FNBMasterUP-images/banner-bg.png) no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 100px 0 0;
} */
.banner-fnb .fnb-banner-txt h1 img {
    height: 150px;
    width: auto;
}
.banner-fnb .fnb-banner-txt h1 {
    font-size: 64px;
    font-weight: 700;
    display: flex;
    gap: 20px;
    align-items: center;
}
.banner-fnb .fnb-banner-txt p {
    font-size: 22px;
    margin: 20px 0 30px;
}
.banner-fnb .fnb-banner-txt .fnb-btns .custom-btn-2 {
    margin-left: 20px;
}
.banner-fnb .fnb-banner-txt h4 {
    font-size: 30px;
    font-weight: 600;
    margin-top: 25px;
}
.fnb .custom-btn-2:hover {
    color: #888888;
}
.fnb .custom-btn-2:hover:after {
    background: #fff;
}
.fnb .custom-btn-2 {
    color: #fff;
    background: #888888;
    border: 1px solid #888888;
}
.fnb-faq .accordion-body {
    padding: 20px;
}
.fnb-faq {
    padding: 60px 0;
    background: #F8F9FC;
}
.fnb-faq .sec-hdng {
    margin-bottom: 30px;
}
.fnb h2.sec-hdng {
    font-size: 44px;
}
.fnb .from-bottom .form-button {
    display: block;
}
.form-button p.hdng-16 {
    display: block;
    width: 100%;
    margin-top: 15px;
    color: #000;
    font-weight: 600;
}
.fnb-form {
    padding: 60px 0;
}
.fnb-form-block .form-feild input, .fnb-form-block .form-feild select {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
    height: 60px;
    width: 100%;
    padding: 15px;
    color: #000;
    font-size: 16px;
    outline: 0;
}
.fnb-form-block .form-feild input[type="radio"] {
    width: auto;
    height: auto;
}
.fnb-form-block .form-feild {
    margin-top: 20px;
}
.fnb-form-block .form-feild .fnb-label {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}
.fnb-form-block .form-feild  .radio-gd {
    display: flex;
    gap: 25px;
}
.fnb-form-block .form-feild .radio-gd label input {
    margin-right: 5px;
}
.fnb-form-block .form-feild input[type="checkbox"] {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    position: relative;
    top: 2px;
}
.about-fnb {
    padding: 60px 0;
}
.about-inner-sec {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
    padding: 50px;
}
.criteria-fnb {
    padding: 60px 0;
    background: #F8F9FC;
}
.criteria-fnb .criteria-hdng {
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}
.criteria-fnb .criteria-hdng h2.sec-hdng {
    margin-bottom: 10px;
}
.criteria-fnb .criteria-hdng {
    margin-bottom: 30px;
}
.criteria-fnb .criteria-box {
    box-shadow: 0px 10px 23px 0px rgb(0 0 0 / 2%);
    border: 1px solid rgb(0 0 0 / 10%);
    background: #fff;
    padding: 40px 30px 20px;
    text-align: center;
    min-height: 160px;
    position: relative;
    margin-top: 20px;
}
.criteria-fnb .criteria-box p {
    margin: 0;
}
.criteria-fnb .criteria-box h4 {
    background: #ed1b24;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin: 0 auto;
    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
}
.about-fnb-service-txt a {
    margin-top: 15px;
}
.about-fnb-service {
    padding: 60px 0;
}
.about-fnb-service .row {
    align-items: center;
}
.about-fnb-service.red-service {
    background: #F8F9FC;
}
.process-fnb {
    padding: 60px 0;
}
.application-box-icon {
    background: #F8F9FC;
    width: 250px;
    padding: 5px;
    border-radius: 20px;
    margin-bottom: 15px;
}
.application-box-icon h5 {
    background: #e91b23;
    padding: 10px 30px;
    border-radius: 20px;
    margin: 0;
    display: inline-block;
    color: #fff;
}
.application-box {
    margin-bottom: 30px;
}
.application-box .application-box-txt h4 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
}
.fnb .site-header {
    display: none;
}
.fnb .fnbmasterup-page .site-header {
    display: block;
}
.btn-fnb {
    margin-left: 25px;
}
/* .fnb .custom-btn {
    background: rgb(0 172 174);
    border: 1px solid rgb(0 172 174);
}
.fnb .custom-btn:hover {
    color: rgb(0 172 174);
} */
.custom-btn.desktop {
    display: none;
}
.fnb .from-bottom .form-button p.message.error-msg {
    bottom: 0;
    color: #ffff;
}
.fnb-form-block .form-feild a {
    font-weight: 600;
    color: #ed1b24;
    text-decoration: none;
}
.terms-banner {
    background: #888;
    padding: 50px 0;
    text-align: center;
}
.terms-banner h1 {
    font-size: 44px;
    color: #fff;
    font-weight: 600;
}
.terms-data .terms-box p.hdng-16 b {
    color: #000;
}
.terms-data .terms-box p.hdng-16.inner {
    margin-left: 20px;
}
.terms-data {
    padding: 60px 0;
}
.terms-data .terms-box:last-child {
    margin-bottom: 0;
}
.terms-data .terms-box {
    margin-bottom: 30px;
}
.terms-data .terms-box p.hdng-16.inner-2 {
    margin-left: 40px;
}
.fnb #chipbot-app, .fnb #chipbot-app-styles, .terms #chipbot-app, .terms #chipbot-app-styles {
    display: none;
}
.ssl-block img {
    width: 150px;
}
.ssl-block ul {
    padding: 0;
    margin: 0;
}
.ssl-block ul li img {
    width: 20px;
}
.ssl-block ul li {
    list-style: none;
    color: #4dbb48;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 5px;
    margin: 5px 0;
}
.ssl-block {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}
.fnb-submit {
    display: flex;
    gap: 50px;
    align-items: center;
    position: relative;
}
.logo-block {
    position: absolute;
    right: 0;
    width: 100px;
}
.fnb .custom-btn {
    background: #5a5a5a;
    border: 1px solid #5a5a5a;
}
.fnb .custom-btn:hover {
    color: #5a5a5a;
}
.fnb-submit button.custom-btn {
    height: 50px;
    padding: 0 50px;
}
.fnb-submit .ssl-block {
    margin: 0;
}
.form-button.fnb-btn a {
    color: #ed1b24;
    text-decoration: none;
    margin-top: -10px;
    display: block;
}
p.message.success-msg {
    background: #56bd47;
    color: #fff;
    padding: 5px 20px;
    display: inline-block;
    margin-top: 20px;
}
.fnb-form-block .OG {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}
.fnb-form-block .OG li {
    list-style: none;
}
.fnb-form-block .OG li img {
    height: 65px;
}
/* .fnb-form-block .OG li:last-child {
    border-left: 1px solid #000;
    padding-left: 20px;
} */




@keyframes marquee {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + var(--offset)));
    }
}
@keyframes marquee-2 {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(calc(-100% + var(--offset)));
    }
}
